<template>
  <div class="employees" ref="employees">
    <div v-if="isLoadingPage">
      <Loader class="no-bg" />
    </div>
    <div v-else class="container-fluid w-auto m-4">

      <h4 class="mb-4">Gestione personale</h4>

      <div class="pretty-card">

        <h5>F24 Dipendenti</h5>
        <div class="row">
          <div class="col-2 box">
            <label for="expected-amount">Importo atteso</label>
            <CellContent
              id="expected-amount"
              :value="expectedAmountF24 ? parseFloat(expectedAmountF24) : null"
              class="py-2"
            />
          </div>
          <div class="col-5 box">
          <b-form-group
            class="mb-3"
            id="input-group-amount-f24"
            :label="`Importo F24 ${currentMonth}`"
            label-for="input-amount-f24"
            :class="{ invalid: !isValidNewAmountF24 && showErrorNewAmountF24 }"
          >
              <CurrencyInput
                :class="{ invalid: !isValidNewAmountF24 && showErrorNewAmountF24 }"
                :key="`f24-input-${loading}`"
                id="input-amount-f24"
                v-model="newAmountF24"
                @blur="showErrorNewAmountF24 = true;"
              />
             <b-form-invalid-feedback :state="false" :class="{ invisible: isValidNewAmountF24 || !showErrorNewAmountF24 }" >
              <i class="fas fa-exclamation-circle"></i> Inserisci un importo valido
            </b-form-invalid-feedback>
          </b-form-group>
          </div>
          <div class="col-5">
            <BankSelect :banks="banks" :selectedItem="newBankF24" @search:blur="showErrorBankF24 = true" @update="updateBankF24" :class="{ invalid: !isValidBankF24 && showErrorBankF24 }"/>
            <b-form-invalid-feedback :state="false" :class="{ invisible: isValidBankF24 || !showErrorBankF24 }" >
              <i class="fas fa-exclamation-circle"></i> Inserisci una banca
            </b-form-invalid-feedback>
          </div>
        </div>

        <h5 class="mb-4">Stipendi dipendenti</h5>

        <div class="row">
          <div class="col-2 box" v-if="cumulativeAmount">
            <label for="expected-amount-salary">Importo atteso</label>
            <CellContent
              id="expected-amount"
              :value="expectedAmountSalary ? parseFloat(expectedAmountSalary) : null"
              class="py-2"
            />
          </div>
          <div class="col-5 box" v-if="cumulativeAmount">
            <b-form-group
              class="mb-3"
              id="input-group-amount-Salary"
              :label="`Importo stipendi ${currentMonth}`"
              label-for="input-amount-Salary"
              :class="{ invalid: !isValidNewAmountSalary && showErrorNewAmountSalary }"
            >
                <CurrencyInput
                  :class="{ invalid: !isValidNewAmountSalary && showErrorNewAmountSalary }"
                  :key="`salary-input-${loading}`"
                  id="input-amount-Salary"
                  v-model="newAmountSalary"
                  @blur="showErrorNewAmountSalary = true;"
                />
              <b-form-invalid-feedback :state="false" :class="{ invisible: isValidNewAmountSalary || !showErrorNewAmountSalary }" >
                <i class="fas fa-exclamation-circle"></i> Inserisci un importo valido
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
          <div class="col-7" v-if="!cumulativeAmount">
            <div v-for="(employee, i) in employees" :key="`employee_${i}`" class="box primary mb-3 pb-0">
              <h5 class="mb-3">{{employee.name}}</h5>
              <div class="row">
                <div class="col-4">
                  <label for="expected-amount-salary">Importo atteso</label>
                  <CellContent
                    id="expected-amount"
                    :value="employee.salary ? parseFloat(employee.salary) : null"
                    class="py-2"
                  />
                </div>
                <div class="col-8">
                  <b-form-group
                    class="mb-3"
                    :id="`input-group-amount-employee-${i}`"
                    :label="`Importo stipendi ${currentMonth}`"
                    :label-for="`input-amount-employee-${i}`"
                  >
                      <CurrencyInput
                        :id="`input-amount-employee-${i}`"
                        :key="`currency-input-${i}-${isUpdating}`"
                        @input="setEmployeeSalary($event, i)"
                      />
                  </b-form-group>
                </div>
              </div>
            </div>
          </div>
          <div class="col-5">
            <BankSelect :banks="banks" :selectedItem="bankSalary" @search:blur="showErrorBankSalary = true" @update="updateBankSalary" :class="{ invalid: !isValidBankSalary && showErrorBankSalary }"/>
            <b-form-invalid-feedback :state="false" :class="{ invisible: isValidBankSalary || !showErrorBankSalary }" >
              <i class="fas fa-exclamation-circle"></i> Inserisci una banca
            </b-form-invalid-feedback>
          </div>
        </div>

        <div class="row d-flex justify-content-end">
          <div class="col-4">
            <custom-button
              :isLoading="loading"
              :disabled="!changes"
              label="Salva"
              class="w-100 mt-4"
              @click.prevent.native="onSubmit"
            />
          </div>
        </div>
      </div>

      <Snackbar ref="snackbar" :text="snackbarText" />
    </div>
  </div>
</template>

<script>
import { BFormGroup, BFormInvalidFeedback } from 'bootstrap-vue';
import { isFilled } from '@/helpers/formValidation.js';

import BankSelect from '@/views/components/BankSelect.vue';
import Button from '@/views/components/Button.vue';
import CellContent from '@/views/components/Table/CellContent.vue';
import CurrencyInput from '@/views/components/CurrencyInput.vue';
import Loader from '@/views/components/Layout/Loading.vue';
// import Popup from '@/views/components/Popup.vue';
import Snackbar from '@/views/components/Snackbar.vue';

export default {
  components: {
    BFormGroup,
    BFormInvalidFeedback,
    BankSelect,
    'custom-button': Button,
    CellContent,
    CurrencyInput,
    Loader,
    // Popup,
    Snackbar,
  },
  data () {
    return {
      banks: [],
      companyId: this.$oauth2.me.companies[0].id || null,

      isLoadingPage: true,
      isUpdating: false,
      loading: false,
      snackbarText: '',

      employees: [],
      newEmployees: [],

      expectedAmountF24: null,
      newAmountF24: null,
      bankF24: null,
      newBankF24: null,

      expectedAmountSalary: null,
      newAmountSalary: null,
      bankSalary: null,
      newBankSalary: null,

      showErrorNewAmountF24: false,
      showErrorBankF24: false,
      showErrorNewAmountSalary: false,
      showErrorBankSalary: false,

      currentMonth: '',
    };
  },
  computed: {
    isValidBankF24 () {
      return isFilled(this.bankF24);
    },
    isValidBankSalary () {
      return isFilled(this.bankSalary);
    },
    isValidNewAmountF24 () {
      // return isFilled(this.newAmountF24);
      return true;
    },
    isValidNewAmountSalary () {
      // return isFilled(this.newAmountSalary);
      return true;
    },
    cumulativeAmount () {
      return !(this.employees.length > 0);
    },
    changes () {
      return isFilled(this.newAmountF24) || isFilled(this.newAmountSalary) || this.newSalaries || (this.bankF24 !== this.newBankF24) || (this.bankSalary !== this.newBankSalary);
    },
    newSalaries () {
      return Boolean(this.newEmployees.find((employee, i) => this.employees[i].salary !== employee.salary));
    },
  },
  methods: {
    getData () {
      this.$api.getSalary(this.companyId)
        .then((res) => {
          this.$log.debug(res.data);
          const data = res.data.salary;
          this.expectedAmountF24 = data.f24Amount;
          this.bankF24 = data.f24Bank;
          this.newBankF24 = data.f24Bank;
          this.expectedAmountSalary = data.amountCumulated;
          this.bankSalary = data.bank;
          this.newBankSalary = data.bank;
          this.employees = data.employees;
          this.newEmployees = JSON.parse(JSON.stringify(data.employees));
        })
        .catch((e) => {
          this.$log.error(e);
        })
        .finally(() => {
          this.isLoadingPage = false;
        });
    },
    getCompanyInfo () {
      this.$api
        .getCompany(this.companyId)
        .then((res) => {
          this.banks = res.data.banks;
        })
        .catch((e) => {
          this.$log.error(e);
        });
    },
    togglePopup (ref) {
      this.$refs[ref].togglePopup();
    },
    updateBankF24 (bank) {
      this.newBankF24 = bank;
    },
    updateBankSalary (bank) {
      this.newBankSalary = bank;
    },
    onSubmit () {
      this.loading = true;
      this.$api.updateSalary(this.companyId, this.newAmountSalary, null, null, this.newAmountF24, this.newBankSalary.id, this.newBankF24.id, this.newEmployees)
        .then((res) => {
          this.$log.debug(res.data);
          this.snackbarText = 'Salvataggio completato';
          this.$refs.snackbar.openSnackbar();
          this.isUpdating = true;

          this.newAmountF24 = null;
          this.newAmountSalary = null;
        })
        .catch((e) => {
          this.$log.error(e);
          // to do error
        })
        .finally(() => {
          this.loading = false;
          this.isUpdating = false;
          this.getData();
        });
    },
    setCurrentMonth () {
      const date = new Date();
      const i = date.getMonth();
      const year = date.getFullYear();
      const months = [
        'gennaio',
        'febbraio',
        'marzo',
        'aprile',
        'maggio',
        'giugno',
        'luglio',
        'agosto',
        'settembre',
        'ottobre',
        'novembre',
        'dicembre'
      ];
      this.currentMonth = `${months[i]}  ${year}`;
    },
    setEmployeeSalary (salary, index) {
      if (salary !== null) {
        this.newEmployees[index].salary = salary;
      } else if (salary === null) {
        this.newEmployees[index].salary = this.employees[index].salary;
      }
    }
  },
  mounted () {
    this.getCompanyInfo();
    this.getData();
    this.setCurrentMonth();
  },
};
</script>

<style lang="scss">
.employees {
  background: $primary-lighter;
  .box.primary {
    background: $primary-lighter;
  }
}
</style>
